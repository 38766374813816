<template>
  <div class="user_details_wrap" >
    <div class="user_content" >
      <img src="./img/13.png" class="user_img" v-if="user.sex == 0" />
      <img src="./img/14.png" class="user_img" v-if="user.sex == 1" />
    </div>
    <div class="data_wrap" >
      <div class="middle_list" >
        <div class="data_item" >
          <div>姓名：</div>
          <div>
            <input class="form_input" placeholder="请输入" v-model="user.name" />
          </div>
        </div>

        <div class="data_item" @click="showSex = true" >
          <div>性别：</div>
          <template v-if="user.sex"  >
            <div v-if="user.sex == 0" >
              <img src="./img/10.png" class="sex_icon" />
              男
            </div>

            <div v-if="user.sex == 1" >
              <img src="./img/9.png" class="sex_icon" />
              女
            </div>
          </template>

          <div v-else >请选择</div>
        </div>
      </div>

      <div class="data_content" @click="showBirth = true" >
        <div>出生日期：</div>
        <div class="date_content" v-if="user.birthday" >
          <div>{{ user.birthday.split('-')[0] }}年</div>
          <div>{{ user.birthday.split('-')[1] }}月</div>
          <div>{{ user.birthday.split('-')[2] }}日</div>
        </div>
        <div v-else class="date_content" >请选择</div>
      </div>

      <div class="middle_list" >
        <div class="data_item" >
          <div>身高：</div>
          <div>
            <input class="form_input" type="number" placeholder="请输入" v-model="user.height" />
            cm
          </div>
        </div>

        <div class="data_item" >
          <div>体重：</div>
          <div>
            <input class="form_input" type="number" placeholder="请输入" v-model="user.weight" />
            kg
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="showSex" position="bottom">
      <van-picker
        title="请选择性别"
        show-toolbar
        :columns="sexList"
        @confirm="sexConfirm"
        @cancel="showSex = false"
        value-key="name"
      />
    </van-popup>
    <van-button class="commit_button" :loading="submitting" @click="handleSubmit" >提交</van-button>

    <van-popup v-model="showBirth" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="请选择生日"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="showBirth = false"
        @confirm="birthConfirm"
      />
    </van-popup>
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import {
  getCustomerVo,
  updateCustomer
} from "@/api";
import moment from 'moment'
import {
  Toast
} from 'vant'
export default {
  name: 'user',
  data() {
    return {
      user: {
        sex: '',
      },
      submitting: false,
      showSex: false,
      sexList: [
        { id: '0', name: '男' },
        { id: '1', name: '女' },
      ],
      showBirth: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2099, 12, 30),
      currentDate: new Date(),
    }
  },
  methods: {
    handleSubmit() {
      const { user } = this
      if(!user.name) {
        Toast('请输入姓名')
        return false
      }

      if(!user.sex) {
        Toast('请选择性别')
        return false
      }

      if(!user.birthday) {
        Toast('请选择出生日期')
        return false
      }

      if(!user.height) {
        Toast('请输入身高')
        return false
      }

      if(!user.height) {
        Toast('请输入体重')
        return false
      }

      this.submitting = true
      updateCustomer({...user}).then(res => {
        if(res.success) {
          Toast('操作成功')
          this.$router.go(-1)
        }else {
          Toast(res.msg)
        }
      })
    },
    birthConfirm(event) {
      this.user.birthday = moment(event).format('YYYY-MM-DD')
      this.showBirth = false
    },
    sexConfirm(event) {
      this.user.sex = event.id
      this.showSex = false
    },
    getInfo() {
      getCustomerVo({}).then(res => {
        if(res.success) {
          let user = res.data
          this.user = {
            name: user.name,
            sex: user.sex,
            height: user.height,
            weight: user.weight,
            birthday: user.birthday,
          }
          console.log({...this.user})
        }
      })
    }
  },
  mounted() {
    this.getInfo()
  }
}
</script>
